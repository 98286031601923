<template>
  <button class="commonBtn" @click="openFullScreenWindow()">
    {{
      $store.state.appConfig.layout.type == "full"
        ? "Exit Full-Layout"
        : "Open Full-Layout"
    }}
  </button>
</template>
<script>


export default {
  name: "LayOutChange",
  // props: {
  //   backgroundColor: {
  //     // type: String,
  //     required: false,
  //     default: null,
  //   },
  //   type: {
  //     required: true,
  //     default: null,
  //   },
  // },
  methods: {
    openFullScreenWindow() {
      if (this.$store.state.appConfig.layout.type == "full") {
        window.close()
      } else {
        // Define the full-screen width and height
        const {width} = window.screen
        const {height} = window.screen

        // Calculate the position to make it full-screen and centered
        const left = 0
        const top = 0

        // Create the window features as a string
        const features = `top=${top},left=${left},width=${width},height=${height}`

        // Open the new window with the same URL and specified features
        const newWindow = window.open("", "_blank", features)

        const currentQuery = { ...this.$route.query }
        let url = this.$route.fullPath

        if (
          currentQuery &&
          currentQuery.layout &&
          currentQuery.layout == "vertical"
        ) {
          url = url.replace("layout=vertical", "layout=full")
        } else {
          // Check if the 'layout' query parameter is already present
          if (!currentQuery.layout || currentQuery.layout !== "full") {
            // If not present or not 'full', add or update the 'layout' query parameter
            url = url.includes("?")
              ? `${url}&layout=full`
              : `${url}?layout=full`
          } else {
            // If 'full' is already present, remove the 'layout' query parameter
            const regex = new RegExp("[?&]layout=full")
            url = url.replace(regex, "")
          }
        }
        // Get the current URL
        const currentUrl = url
        // Set the location to the current URL to load the same page
        newWindow.location.href = currentUrl
      }
    },
  },
}
</script>
