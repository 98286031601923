<template>
  <div>
    <BoardFilter
      v-if="projectDtl
      "
      :project-dtl="projectDtl"
      :non-board="true"
      :from="'generalBoard'"
      :board-project-id="projectDtl && projectDtl.id"
    />
    <BoardShimmer v-if="loader" />

    <draggable
      v-else
      handle=".control-dragcom"
      class="d-flex boardDesign board-outer-view"
      :disabled="!userInfo || userInfo.role !== 'SA'"
      :list="$store.state.app.generalBoardTaskStatus"
      @end="updateDisplayOrder($event)"
    >
      <div
        v-for="(generalBoardStatus, index) in generalBoardTaskStatusList"
        :key="index"
        class="board-status control-dragcom"
        :class="generalBoardStatus.tasks.length == 0 ? 'draggable-box' : null"
      >
        <div class="board-status-header">
          <b-input-group
            v-if="sectionId == generalBoardStatus.id && userInfo.role == 'SA'"
            class="input-group-merge section-group"
          >
            <b-form-input
              ref="myTextField"
              v-model="generalBoardStatus.name"
              placeholder="Add status"
              class="section-textfield"
            />
            <b-input-group-append is-text>
              <feather-icon
                class="mr-25 cursor-pointer"
                icon="CheckIcon"
                size="18"
                @click="updateSection(generalBoardStatus, index)"
              />
            </b-input-group-append>
          </b-input-group>
          <p
            v-else
            class="board-status-title"
            @click="sectionParentTitle(generalBoardStatus.id)"
          >
            {{ generalBoardStatus.name }}
            <feather-icon
              v-if="generalBoardStatus && generalBoardStatus.category == 'done'"
              icon="CheckIcon"
              size="18"
              class="cursor-pointer"
              color="green"
            />
          </p>
          <div
            v-if="(userInfo.users && userInfo.role == 'SA')
            "
            class="d-flex align-items-center"
          >
            <span
              v-b-modal.addtaskdata
              class="add-board"
              @click="addtaskData(generalBoardStatus)"
            >
              <feather-icon
                icon="PlusIcon"
                size="16"
              />
            </span>
            <!-- edit and delete status name block -->
            <div
              v-if="userInfo && userInfo.role == 'SA'
              "
              class="boardDropdown"
            >
              <b-dropdown
                toggle-class="text-decoration-none"
                no-caret
                append-to-body
              >
                <template v-slot:button-content>
                  <button class="edit-del-button">
                    <feather-icon
                      icon="MoreHorizontalIcon"
                      size="21"
                      class="align-middle edit-data-delete"
                    />
                  </button>
                </template>
                <b-dropdown-item
                  class="edit-delete-item"
                  @click="sectionParentTitle(generalBoardStatus.id)"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="16"
                    class="mr-50 edit-icons"
                  />
                  <span class="edit-data">Edit status name</span>
                </b-dropdown-item>
                <b-dropdown-item
                  class="edit-delete-item"
                  @click="deleteConfirmation(generalBoardStatus, index)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    size="16"
                    class="mr-50 edit-icons"
                  />
                  <span class="edit-data">Delete status</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
        <!-- :class="userInfo && userInfo.role == 'SA' ? 'draggable-list board-sa-draggable-list' : 'draggable-list board-employee-draggable-list'" -->
        <draggable
          group="all-users"
          class="draggable-list board-sa-draggable-list"
          ghost-class="moving-card"
          :list="generalBoardStatus.tasks"
          :animation="200"
          @change="getChangeData($event, generalBoardStatus.id)"
        >
          <div
            v-for="(task, taskIndex) in generalBoardStatus.tasks"
            :key="taskIndex"
            class="task projectListnew min-height"
          >
            <div class="d-flex">
              <b-form-input
                v-if="taskid == task.id && textEdit"
                id="editTask"
                v-model="tasktitle"
                type="text"
                :value="task.title"
                placeholder="Edit task"
                name="editTask"
                class="w-75"
                @keyup.enter="onEnter(task.id, task.title)"
                @blur="textEdit = false"
              />
              <p
                v-else
                v-b-modal.addtaskdata
                class="board-task-heading"
                @click="viewTask(task.id)"
              >
                {{ task.title }}
              </p>
              <div class="boardDropdown">
                <b-dropdown
                  toggle-class="text-decoration-none"
                  no-caret
                  append-to-body
                >
                  <template v-slot:button-content>
                    <button class="edit-del-button">
                      <feather-icon
                        icon="MoreHorizontalIcon"
                        size="21"
                        class="align-middle edit-data-delete"
                      />
                    </button>
                  </template>
                  <b-dropdown-item
                    v-if="userInfo.users && userInfo.role == 'SA'"
                    class="edit-delete-item"
                    @click="editText(task.id, task.title)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      size="16"
                      class="mr-50 edit-icons"
                    />
                    <span class="edit-data">Edit task name</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="userInfo.users && userInfo.role == 'SA'"
                    class="edit-delete-item"
                  >
                    <feather-icon
                      icon="CopyIcon"
                      size="16"
                      class="mr-50 edit-icons"
                    />
                    <span
                      class="edit-data"
                      @click="duplicateTask(task.id)"
                    >Duplicate Task</span>
                  </b-dropdown-item>
                  <b-dropdown-item class="edit-delete-item">
                    <feather-icon
                      icon="Link2Icon"
                      size="16"
                      class="mr-50 edit-icons link-icon"
                    />
                    <span
                      class="edit-data"
                      @click="copyLink(task.id)"
                    >
                      Copy task link</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="userInfo.users && userInfo.role == 'SA'"
                    class="edit-delete-item"
                    @click="archiveTask(task.id)"
                  >
                    <feather-icon
                      icon="FileIcon"
                      size="16"
                      class="mr-50 edit-icons"
                    />
                    <span class="edit-data">Archive</span>
                  </b-dropdown-item>
                  <!-- <b-dropdown-item class="edit-delete-item">
                    <feather-icon
                      icon="Trash2Icon"
                      size="16"
                      class="trashicon mr-50"
                    />
                    <span class="delete-data">Delete task</span>
                  </b-dropdown-item> -->
                </b-dropdown>
              </div>
            </div>
            <!-- <span class="sprint">DSA T-01</span>
            <span class="sprint">DSA T-01</span>
            <span class="sprint">DSA T-01</span> -->
            <!--<p class="board-task-des">
               {{ task.description }}
            </p>-->
            <div
              v-b-modal.addtaskdata
              class="my-50"
              @click="viewTask(task.id)"
            >
              <button
                v-for="(tag, tindex) in task.tags"
                :key="tindex"
                class="task-tag"
              >
                <span class="task-tag-title">{{ tag.tag }}</span>
                <!-- <feather-icon icon="XIcon" size="15"></feather-icon> -->
              </button>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <p
                v-b-modal.addtaskdata
                class="assignTitle"
                @click="viewTask(task.id)"
              >
                Assigned to
              </p>
              <p
                v-b-tooltip.hover.top="'Ticket No.'"
                class="assignTitle"
                style="color: #2178fb"
              >
                {{ task.number }}
              </p>
            </div>

            <div
              v-b-modal.addtaskdata
              class="d-flex justify-content-between flex-wrap"
              @click="viewTask(task.id)"
            >
              <b-avatar-group>
                <b-avatar
                  v-if="task.assignee_user"
                  v-b-tooltip.hover
                  class="pull-up"
                  :src="task.assignee_user.picture"
                  :text="task.assignee_user.full_name | avatarText"
                  :title="task.assignee_user.full_name"
                />
                <b-avatar
                  v-if="task.reporter_user"
                  v-b-tooltip.hover
                  class="pull-up"
                  variant="primary"
                  :src="task.reporter_user.picture"
                  :text="task.reporter_user.full_name | avatarText"
                  :title="task.reporter_user.full_name"
                />
              </b-avatar-group>
              <div class="d-flex align-items-center">
                <b-icon
                  v-if="task.priority == 'high'"
                  v-b-tooltip.hover.top="'High'"
                  icon="Flag-fill"
                  class="flag-fill-priority text-danger"
                />
                <b-icon
                  v-if="task.priority == 'med'"
                  v-b-tooltip.hover.top="'Medium'"
                  icon="Flag-fill"
                  class="flag-fill-priority-medium flag-icon"
                />
                <b-icon
                  v-if="task.priority == 'low'"
                  v-b-tooltip.hover.top="'Low'"
                  icon="Flag-fill"
                  class="flag-fill-priority-low"
                />
                <p class="task-date">
                  {{ task.due_date | timeDayFormat }}
                </p>
              </div>
            </div>
          </div>
        </draggable>
      </div>

      <!-- New task status add -->
      <div
        v-if="!addNewTextfield && userInfo && userInfo.role === 'SA'"
        class="add-new-board-status-btn"
      >
        <div class="d-flex">
          <span
            v-if="!addNewTextfield"
            v-b-modal.addtaskdata
            class="add-board"
            @click="addNewTextfield = true"
          >
            <feather-icon
              icon="PlusIcon"
              size="18"
              class="mb-25"
            />
            <span class="add-new-status-title ml-25">Add New Status</span>
          </span>
        </div>
      </div>
      <div
        v-if="addNewTextfield"
        class="add-new-board-status"
      ><validation-observer ref="boardStatus">
         <div v-if="addNewTextfield">
           <validation-provider
             #default="{ errors }"
             name="Task Status"
             rules="required"
           >
             <b-form-input
               v-if="addNewTextfield"
               id="testing1"
               v-model="statusName"
               placeholder="Add new status *"
               type="text"
             /><small class="text-danger">{{ errors[0] }}</small>
           </validation-provider>

           <b-form-group
             v-if="addNewTextfield"
             class="mt-1"
           >
             <validation-provider
               #default="{ errors }"
               name="Category"
               rules="required"
             >
               <v-select
                 v-model="category"
                 :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                 label="title"
                 placeholder="Select Category *"
                 :options="option"
                 :reduce="(option) => option.value"
               />
               <small class="text-danger">{{ errors[0] }}</small>
             </validation-provider>
           </b-form-group>
         </div>
       </validation-observer>
        <div v-if="addNewTextfield">
          <button
            class="board-status-btn commonBtn"
            :disabled="statusLoader"
            @click.prevent="validateAddStatus"
          >
            Save
          </button>
          <button
            class="board-status-btn commonBtn-outline ml-1"
            @click.prevent="addNewTextfield = false"
          >
            Cancel
          </button>
        </div>
      </div>
    </draggable>

    <add-task
      id="addtaskdata"
      :project-dtl="projectDtl"
      :task-i-d="viewTaskID"
      :board-status="boardStatus"
      @disableTaskID="disableTaskID"
    />

    <!-- Delete task status block -->
    <b-modal
      v-if="statuses && statuses.length"
      :id="id"
      v-model="isStatusModal"
      cancel-variant="outline-secondary"
      centered
      size="lg"
    >
      <template #modal-title>
        <h3 class="sprint-from-title">
          Tasks
        </h3>

        <div class="complete-underline" />
      </template>
      <validation-observer ref="boardStatusTasksRules">
        <p class="note m-0 pb-50">
          {{ status }} Status Task count : {{ specificTaskCount }}
        </p>
        <validation-provider
          #default="{ errors }"
          name="Task Status"
          rules="required"
        >
          <v-select
            id="sprintID"
            v-model="task_status"
            label="name"
            :options="statuses"
            class="select-size-lg select-task-option cu-select"
            name="sprint_id"
            :reduce="(st) => st.id"
            placeholder="Select Status"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </validation-observer>
      <template #modal-footer>
        <b-button
          class="complete-btn"
          :disabled="loader"
          @click="moveTaskValidation"
        >
          <b-spinner
            v-if="loader"
            small
            class="spinner"
          />Save
        </b-button>
        <b-button
          class="cancel-btn"
          @click="closeModal"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>
    <b-modal
      v-else
      :id="id"
      v-model="isStatusModal"
      cancel-variant="outline-secondary"
      centered
      hide-footer
      size="lg"
    >

      <h3
        class="sprint-from-title pt-2 "
        style="color:#2178fb"
      >
        Create new project status with Done category & move current task on that.
      </h3>

    </b-modal>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { eventBus } from '@/main'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AddTask from './AddTaskModal.vue'
import BoardFilter from '../project/Board/boardFilter.vue'
import BoardShimmer from '../shimmer/BoardShimmer.vue'

export default {
  name: 'Board',
  components: {
    draggable,
    // AddTask,
    BDropdown,
    BDropdownItem,
    BoardFilter,
    AddTask,
    BoardShimmer,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      projectTasks: [],
      projectDtl: null,
      textEdit: false,
      taskid: null,
      tasktitle: null,
      taskStatus: [],
      viewTaskID: null,
      viewTaskDtl: false,
      boardStatus: {},
      startFilterDate: null,
      endFilterDate: null,
      assigneefilter_id: [],
      loader: false,
      tags: [],
      search: null,
      taskLoader: false,
      isStatusModal: false,
      task_status: null,
      specificTaskCount: null,
      list: [],
      addNewTextfield: false,
      option: [
        { title: 'To Do', value: 'to_do' },
        { title: 'In Progress', value: 'in_progress' },
        { title: 'Done', value: 'done' },
      ],
      category: 'to_do',
      statusName: null,
      status: null,
      id: null,
      sectionId: null,
      statusDetail: null,
    }
  },
  computed: {
    statuses() {
      const currentStatus = this.$store.state.app.generalBoardTaskStatus.filter(a => (a.id == this.id) && a.category === 'done')
      let status = []
      if (currentStatus && currentStatus.length) {
        status = this.$store.state.app.generalBoardTaskStatus.filter(a => (a.id !== this.id && a.is_active && a.category === 'done'))
      } else {
        status = this.$store.state.app.generalBoardTaskStatus.filter(a => (a.id !== this.id && a.is_active) || a.id === 'archive')
      }
      if (status && status.length) status.sort((a, b) => a.name - b.name)
      return status.sort((a, b) => a.name - b.name)
    },
    generalBoardTaskStatusList() {
      return this.$store.state.app.generalBoardTaskStatus.filter(status => status.id != 'archive')
    }
  },
  watch: {
    isStatusModal(nv) {
      if (!nv) {
        setTimeout(() => {
          this.task_status = null
        }, 500)
      }
    },
  },
  destroyed() {
    this.$store.dispatch('app/CLEAR_BOARD_BACKGROUND_COLOR')
    eventBus.$off('backgroundColorSet')
    eventBus.$off('generalBoardReloadCompleteTaskList')
    eventBus.$off('generalBoardApplyFilters')
    eventBus.$off('generalClearFilters')
  },
  mounted() {
    this.getProject()
    if (
      this.$store.state.app.generalBoardColor
      && this.$store.state.app.generalBoardColor.code
    ) {
      eventBus.$emit(
        'backgroundColorSet',
        this.$store.state.app.generalBoardColor.code,
      )
      this.$store.state.app.boardBackGroundColor = this.$store.state.app.generalBoardColor.code
    }

    eventBus.$on('generalBoardReloadCompleteTaskList', data => {
      if (data) {
        this.viewTaskDtl = false
        this.getProjectTaskLists()
      }
    })
    eventBus.$on('generalBoardApplyFilters', data => {
      if (data) {
        this.assigneefilter_id = data.assignee
        this.startFilterDate = data.start_date
        this.endFilterDate = data.end_date
        this.tags = data.tags
        this.search = data.search
        this.getProjectTaskLists()
      }
    })
    eventBus.$on('generalClearFilters', data => {
      if (data) {
        this.assigneefilter_id = data.assignee
        this.startFilterDate = data.start_date
        this.endFilterDate = data.end_date
        this.getProjectTaskLists()
      }
    })
    /* close alert we click in sidebar */
    eventBus.$on('closeSweetAlert', () => {
      this.$swal.close()
    })
  },
  beforeRouteLeave(to, from, next) {
    eventBus.$emit('backgroundColor', 'backgroundColor')
    setTimeout(() => {
      next()
    }, 100)
  },
  methods: {
    // Cursor focus edit textfield in status title
    sectionParentTitle(val) {
      this.sectionId = val
    },
    validateAddStatus() {
      this.$refs.boardStatus.validate().then(success => {
        if (success) {
          this.saveStatus()
        }
      })
    },
    // create new status section
    async saveStatus() {
      this.statusLoader = true
      const input = {
        project_id: this.projectDtl.id,
        name: this.statusName ? this.statusName : 'Untitled',
        type: 'general',
        category: this.category ? this.category : 'to_do',
      }
      const response = await this.getHTTPPostResponse(
        'project/task-status/create',
        input,
        false,
      )
      if (response && response.status === 200) {
        this.getAllTaskStatus()
        this.statusName = null
        this.category = null
        this.addNewTextfield = false
      }
      this.statusLoader = false
    },

    async updateSection(data, index) {
      const input = {
        project_id: this.projectDtl.id,
        name: data.name ? data.name : 'Untitled',
        type: 'general',
        category: data.category,
      }
      const response = await this.getHTTPPutResponse(
        `project/task-status/update/${data.id}`,
        input,
      )

      if (response && response.status === 200) {
        this.$store.state.app.generalBoardTaskStatus[index].name = data.name ? data.name : 'Untitled'
        this.sectionId = null
      }
    },

    closeModal() {
      this.isStatusModal = false
    },

    /* display order update */
    async updateDisplayOrder(val) {
      const input = {
        project_id: this.projectDtl.id,
        id: val?.item?._underlying_vm_.id,
        display_order: val.newIndex + 1,
        type: 'general',
      }
      const response = await this.getHTTPPostResponse(
        'project/task-status/update-order',
        input,
      )
      if (response && response.status === 200) {
        // this.getAllTaskStatus()
      }
    },

    /* if delete project status if this status have any then move to other status for that open modal for update status other wise take delete conformation  */
    async deleteConfirmation(item) {
      this.status = item.name
      this.statusDetail = item
      this.specificTaskCount = item.project_tasks_count

      this.id = item.id
      this.is_status_active = item.is_active
      const input = {
        project_id: this.projectDtl.id,
        id: item.id,
      }
      const response = await this.getHTTPPostResponse(
        'project/task-status/status-tasks-count',
        input,
      )
      if (response && response.status === 200) {
        this.specificTaskCount = response && response.data && response.data.status_task_count && response.data.status_task_count.length && response.data.status_task_count[0].project_tasks_count
        if (!this.specificTaskCount) {
          this.$swal({
            title: 'Are you sure?',
            icon: 'info',
            html: '<p>Once you delete you will not able to recover this record.</p>',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Delete',
            confirmButtonAriaLabel: 'Thumbs up, great!',
            cancelButtonAriaLabel: 'Thumbs down',
            customClass: {
              confirmButton: 'btn confirm',
              cancelButton: 'btn cancel ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.deleteStatus(item.id)
            }
          }) // latest
        } else if (this.specificTaskCount) {
          this.delId = item.id
          this.isStatusModal = true
        }
      }

      return ''
    },
    /* particular status all task move to new selected status */
    async moveTask() {
      const input = {
        id: this.id,
        move_to: this.task_status,
        status_id: this.statusDetail && this.statusDetail.id,
      }
      if (this.delId) {
        this.deleteStatus(this.delId)
      } else {
        const response = await this.getHTTPPostResponse(
          'project/task-status/change-status',
          input,
        )
        if (response && response.status === 200) {
          this.isStatusModal = false
          this.task_status = null
          this.getAllTaskStatus()
        }
      }
    },
    /* delete project status */
    async deleteStatus(id) {
      const input = {
        id,
        move_to: this.task_status ? this.task_status : 'archive',
      }
      const response = await this.getHTTPPostResponse(
        'project/task-status/delete',
        input,
        true,
      )
      if (response && response.status === 200) {
        this.isStatusModal = false
        this.getAllTaskStatus()
      }
    },

    /* check validation for new selected status */
    moveTaskValidation() {
      this.$refs.boardStatusTasksRules.validate().then(success => {
        if (success) {
          this.moveTask()
        } else {
          return false
        }
      })
    },

    // Board Background color set
    async backgroundColorSet() {
      const response = await this.getHTTPGetResponse(
        `project/board-color/view/${this.projectDtl.id}`,
      )
      if (response && response.data) {
        this.$store.commit(
          'app/UPDATE_GENERAL_BOARD_COLOR',
          response.data.project_color,
        )
        this.$store.state.app.boardBackGroundColor = response.data.project_color && response.data.project_color.code
      }
    },
    // Get All Project Task Status (type = general)
    async getAllTaskStatus() {
      const input = {
        sort_field: 'display_order',
        sort_order: 'asc',
        type: 'general',
        is_active: true,
        project_id: this.projectDtl && this.projectDtl.id ? this.projectDtl.id : null,
      }
      const response = await this.getHTTPPostResponse(
        'project/task-status',
        input,
        false,
      )
      if (response && response.data) {
        // this.taskStatus = response.data.task_statuses ;
        this.getProjectTaskLists()
        this.$store.commit(
          'app/UPDATE_GENERAL_BOARD_TASK_STATUS',
          response.data.task_statuses,
        )
        const a = response.data.task_statuses
        let displayOrder = 0
        displayOrder = a && a.length && a[a.length - 1].display_order ? a[a.length - 1].display_order : 0
        this.$store.state.app.generalBoardTaskStatus.push({ id: 'archive', name: 'archive', display_order: displayOrder + 1 })
      }
    },
    // Copy Link Task
    copyLink(taskId) {
      const locationHost = location.host
      navigator.clipboard.writeText(
        `${locationHost + this.$router.options.base}general-board?taskId=${taskId}`,
      )
      this.$bvToast.toast('Link copied successfully', {
        toaster: 'b-toaster-top-right',
        solid: true,
        title: 'Success',
        variant: 'success',
      })
    },
    // Archive Task Title
    async archiveTask(id) {
      const input = {
        project_task_id: id,
      }
      const response = await this.getHTTPPostResponse(
        'project/task/task-archive',
        input,
        true,
      )
      if (response && response.data) {
        this.getProjectTaskLists()
      }
    },

    // disable TaskID form Emit
    disableTaskID() {
      this.viewTaskID = null
      this.boardStatus = {}
      // this.$router.back();
      this.$router.push({
        name: 'board',
      }).catch(err => {
        // Ignore the NavigationDuplicated error
        if (err.name !== 'NavigationDuplicated') {
          console.log(err)
        }
      })
    },
    //  Add Task Modal open and viewTaskID null
    addtaskData(val) {
      this.boardStatus = val
      this.viewTaskID = null
    },
    viewTask(id) {
      this.viewTaskID = id
      const { query } = this.$route

      this.$router.push({
        query: { projectId: query.id, taskId: id, layout: this.currentLayout },
      }).catch(err => {
        // Ignore the NavigationDuplicated error
        if (err.name !== 'NavigationDuplicated') {
          console.log(err)
        }
      })
      this.viewTaskDtl = true
    },

    editText(id, title) {
      if (id) {
        this.taskid = id
        this.textEdit = true
        this.tasktitle = title
      }
    },

    async onEnter(id, title) {
      const input = {
        project_id: this.projectDtl.id,
        title: this.tasktitle ? this.tasktitle : title,
        project_task_id: id,
      }
      const response = await this.getHTTPPostResponse(
        'project/task/update-title',
        input,
        false,
      )
      if (response && response.data) {
        this.taskid = null
        this.textEdit = false
        this.getProjectTaskLists()
      }
    },

    async getProject() {
      const response = await this.getHTTPPostResponse(
        'project/remote-project',
        {},
        false,
      )

      if (response && response.data) {
        /* static project name set */
        if (response.data.project && response.data.project.name) {
          response.data.project.name = 'General Board'
        }
        this.projectDtl = response.data.project
        if (this.$route.query.taskId) {
          this.viewTask(this.$route.query.taskId)
          this.$root.$emit('bv::toggle::modal', 'addtaskdata')
        }
        if (
          this.$store.state.app.generalBoardColor
          && this.$store.state.app.generalBoardColor.code
        ) {
          eventBus.$emit(
            'backgroundColorSet',
            this.$store.state.app.generalBoardColor.code,
          )
        } else {
          this.backgroundColorSet()
        }

        this.getAllTaskStatus()
      }
    },

    async getProjectTaskLists() {

      this.loader = true
      const input = {
        user_id:
          this.assigneefilter_id && this.assigneefilter_id.length
            ? this.assigneefilter_id
            : [],
        tags: this.tags,
        search: this.search,
      }

      const response = await this.getHTTPPostResponse(
        'project/task/board-tasks',
        input,
        false,
      )
      if (response && response.data) {
        // this.projectTasks = response.data.project_tasks && response.data.project_tasks.length ? response.data.project_tasks : [];
        const allStatus = []
        this.$store.state.app.generalBoardTaskStatus.forEach(status => {
          status.tasks = []
          if (
            response.data.project_tasks
            && response.data.project_tasks.length
          ) {
            response.data.project_tasks.forEach(task => {
              if (status.id == task.status_id) {
                status.tasks.push(task)
              }
            })
          }
          allStatus.push(status)
          //
        })

        this.$store.state.app.generalBoardTaskStatus = allStatus
      }
      this.loader = false
    },

    getChangeData(event, status) {
      const eventName = Object.keys(event)[0]
      if (eventName == 'added') {
        this.updateStatus(event.added.element.id, status)
      }
    },
    async duplicateTask(id) {
      const input = {
        project_task_id: id,
        project_id: this.$route.params.id,
      }
      const response = await this.getHTTPPostResponse(
        'project/task/duplicate',
        input,
        false,
      )
      if (response && response.status == 200) {
        this.getProjectTaskLists()
      }
    },
    async updateStatus(id, status) {
      // this.loader = true;
      const input = {
        // task_status: status,
        status_id: status,
        project_id: this.$route.params.id,
        is_non_project: true,
      }
      const response = await this.getHTTPPostResponse(
        `project/task/non-board-status/${id}`,
        input,
        false,
      )
      if (response && response.status == 200) {
        // this.getTaskDetails();
      }
      // this.loader = false;
    },

  },
}
</script>

<style>
@import "../../assets/scss/component-css/board.css";

/* Unfortunately @apply cannot be setup in codesandbox,
but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
.moving-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 1px solid #4299e1;
}

/* Add section text field css */
.section-group {
  border: 1px solid !important;
}

.section-group .section-textfield {
  background: transparent;
}

.section-group .input-group-text {
  background: transparent;
}

/*  */
.add-new-board-status {
  padding: 12px;
  width: 300px;
  min-width: 300px;
  margin-right: 20px;
  background-color: #f1f2f4;
  border-radius: 12px;
}

.add-new-board-status-btn {
  padding: 14px;
  width: 300px;
  min-width: 300px;
  margin-right: 20px;
  background-color: #f1f2f4;
  border-radius: 12px;
}

.add-new-status-title {
  font-size: 18px;
  color: #5e5873;
  font-weight: 700;

}

.min-height {
  min-height: 150px !important;
}
</style>
