<template>
  <div>
    <b-button
      v-b-tooltip.hover.top
      v-b-toggle.sidebar-right
      title="Background Color"
      class="apply-filter-btn"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 20 20"
      >
        <path
          fill=""
          d="M2 10.664V13.5A2.5 2.5 0 0 0 4.5 16h11a2.5 2.5 0 0 0 2.5-2.5v-7A2.5 2.5 0 0 0 15.5 4H9.478l.671.672c.104.104.2.213.285.328H15.5A1.5 1.5 0 0 1 17 6.5v7a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 3 13.5v-1.836l-1-1ZM5 1.5a.5.5 0 0 1 1 0v1.174c.48.022.954.217 1.32.583L9.443 5.38a2 2 0 0 1 0 2.828l-2.828 2.829a2 2 0 0 1-2.829 0L1.664 8.914a2 2 0 0 1 0-2.828l2.828-2.829c.153-.153.325-.276.508-.369V1.5Zm0 4V4.164L2.664 6.5h6.32a.996.996 0 0 0-.249-.414L6.614 3.964A.996.996 0 0 0 6 3.676V5.5a.5.5 0 0 1-1 0Zm-2.922 2c0 .256.098.512.293.707l2.121 2.121a1 1 0 0 0 1.415 0L8.735 7.5H2.078Zm8.084.926l.215-.259a.5.5 0 0 1 .746 0l.215.26c.124.157.29.38.458.64c.318.497.704 1.228.704 1.933c0 1.009-.673 2-1.75 2C9.672 13 9 12.009 9 11c0-.705.386-1.436.704-1.932c.167-.261.333-.484.458-.642Zm.588.881a6.813 6.813 0 0 0-.204.3C10.24 10.085 10 10.605 10 11c0 .293.1.566.248.749a.62.62 0 0 0 .502.251a.618.618 0 0 0 .502-.251A1.22 1.22 0 0 0 11.5 11c0-.396-.24-.915-.546-1.393a6.815 6.815 0 0 0-.204-.3Z"
        />
      </svg>
    </b-button>
    <b-sidebar
      id="sidebar-right"
      bg-variant="white"
      right
      backdrop
      shadow
      class="sidebar-form add-sprint check-icon"
    >
      <p class="select-color mx-2 pb-25">
        Colors
      </p>
      <div class="color-content">
        <div
          class="bg-1"
          @click="changeColor('bg-1')"
        >
          <feather-icon
            v-if="iconsBg1"
            icon="CheckIcon"
          />
        </div>
        <div
          class="bg-2"
          @click="changeColor('bg-2')"
        >
          <feather-icon
            v-if="iconsBg2"
            icon="CheckIcon"
          />
        </div>
        <div
          class="bg-3"
          @click="changeColor('bg-3')"
        >
          <feather-icon
            v-if="iconsBg3"
            icon="CheckIcon"
          />
        </div>
        <div
          class="bg-4"
          @click="changeColor('bg-4')"
        >
          <feather-icon
            v-if="iconsBg4"
            icon="CheckIcon"
          />
        </div>
        <div
          class="bg-5"
          @click="changeColor('bg-5')"
        >
          <feather-icon
            v-if="iconsBg5"
            icon="CheckIcon"
          />
        </div>
        <div
          class="bg-6"
          @click="changeColor('bg-6')"
        >
          <feather-icon
            v-if="iconsBg6"
            icon="CheckIcon"
          />
        </div>
        <div
          class="bg-7"
          @click="changeColor('bg-7')"
        >
          <feather-icon
            v-if="iconsBg7"
            icon="CheckIcon"
          />
        </div>
      </div>
      <template #footer>
        <div class="form-button d-flex my-2">
          <button
            class="btn side-bar-btn"
            @click="saveBgColor"
          >
            Save
          </button>
          <button
            class="btn side-bar-btn"
            @click="clearBgColor"
          >
            Close
          </button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { BButton, BSidebar, VBToggle } from 'bootstrap-vue'
import { eventBus } from '@/main'

export default {
  components: {
    BButton,
    BSidebar,
    // SidebarContent,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    projectDtl: {
      type:Object,
      required: true,
      default: () => {},
    },
    type: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      iconsBg1: false,
      iconsBg2: false,
      iconsBg3: false,
      iconsBg4: false,
      iconsBg5: false,
      iconsBg6: false,
      iconsBg7: false,
    }
  },
  mounted() {
    if (
      this.$store.state.app.projectBoardColor
      && this.$store.state.app.projectBoardColor.code
      && this.$store.state.app.projectBoardColor.project_id
        == this.$route.params.id3
    ) {
      this.setBgColor(this.$store.state.app.projectBoardColor.code)
    } else if (!this.type) {
      this.backgroundColorSet()
    }
  },
  methods: {
    // Board Background color set
    async backgroundColorSet() {
      const response = await this.getHTTPGetResponse(
        `project/board-color/view/${this.projectDtl.id}`,
      )
      if (response && response.data && response.data.project_color) {
        this.$store.commit(
          'app/UPDATE_PROJECT_BOARD_COLOR',
          response.data.project_color,
        )

        this.$store.state.app.boardBackGroundColor = response.data.project_color && response.data.project_color.code

        this.setBgColor(this.$store.state.app.boardBackGroundColor)
      }
    },

    changeColor(colors) {
      this.iconsBg1 = false
      this.iconsBg2 = false
      this.iconsBg3 = false
      this.iconsBg4 = false
      this.iconsBg5 = false
      this.iconsBg6 = false
      this.iconsBg7 = false
      this.setBgColor(colors)
    },
    setBgColor(colors) {
      switch (colors) {
        case 'bg-1':
          this.iconsBg1 = true
          this.bgColor = 'bg-1'

          break
        case 'bg-2':
          this.iconsBg2 = true
          this.bgColor = 'bg-2'

          break
        case 'bg-3':
          this.iconsBg3 = true
          this.bgColor = 'bg-3'

          break
        case 'bg-4':
          this.iconsBg4 = true
          this.bgColor = 'bg-4'

          break
        case 'bg-5':
          this.iconsBg5 = true
          this.bgColor = 'bg-5'

          break
        case 'bg-6':
          this.iconsBg6 = true
          this.bgColor = 'bg-6'
          break
        default:
          this.iconsBg7 = true
          this.bgColor = 'bg-7'
          break
      }

      // this.$store.state.app.projectBoardColor.code = this.bgColor;
      eventBus.$emit('backgroundColorSet', this.bgColor)
    },
    // Save Board Background color
    async saveBgColor() {
      const input = {
        project_id: this.projectDtl.id,
        code: this.bgColor,
      }
      const response = await this.getHTTPPostResponse(
        'project/board-color/update-color',
        input,
        true,
      )
      if (response.status == 200) {
        this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
        if (this.type) {
          this.$store.state.app.generalBoardColor.code = this.bgColor
        } else {
          this.$store.state.app.projectBoardColor.code = this.bgColor
        }

        eventBus.$emit('backgroundColorSet', this.bgColor)
      }
    },
    async clearBgColor() {
      const response = await this.getHTTPGetResponse(
        `project/board-color/view/${this.projectDtl.id}`,
      )

      if (response && response.data) {
        if (this.type) {
          this.$store.state.app.generalBoardColor.code = this.bgColor
        } else if (
          this.$store.state.app.projectBoardColor
            && this.$store.state.app.projectBoardColor.code
        ) {
          this.$store.state.app.projectBoardColor.code = this.bgColor
        }

        if (response.data.project_color) {
          eventBus.$emit('backgroundColorSet', response.data.project_color.code)
        }
        this.iconsBg1 = false
        this.iconsBg2 = false
        this.iconsBg3 = false
        this.iconsBg4 = false
        this.iconsBg5 = false
        this.iconsBg6 = false
        this.iconsBg7 = false
        this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
      }
    },
  },
}
</script>
<style lang="scss">
@import "../../../../src/assets/scss/variables/_variables.scss";
@import "../../../assets/scss/component-css/board.css";

.check-icon .feather {
  width: 125px;
  height: 43px;
  margin-bottom: -63px;
  color: #07f183 !important;
}
</style>
