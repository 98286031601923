<template>
  <div
    :class="$store.state.appConfig.layout.type == 'full' ? 'card-filter-section board-filter-parent m-0' : 'card-filter-section board-filter-parent'">
    <div class="d-flex align-items-center flex-wrap justify-content-between">

      <div class="d-flex align-items-center justify-content-between w-100 flex-wrap">
        <div v-if="!nonBoard || nonBoard && userInfo && userInfo.role == 'SA'" class="d-flex align-items-center">
          <p class="filter-title">
            Filters:
          </p>
          <div v-show="from == 'generalBoard'" class="w-lg-100 test">
            <custom-dropdown v-model="board_project_id" :options="$store.state.app.productiveProjectList"
              :label="projectDtl && projectDtl.name" icon="TrelloIcon" :hide-close="true"
              :class="projectDtl && projectDtl.code == 'ROJ' && userInfo && userInfo.role == 'EMP' ? 'mr-5' : ''" />
          </div>
          <div v-show="from == 'generalBoard'">
            |
          </div>
          <div class="w-lg-100">
            <custom-dropdown v-model="assignee_id" :options="assigneeUsers" label="Assignee" multiple icon="EditIcon" />
          </div>
          <div class="w-lg-100">
            <custom-dropdown v-model="tags" :options="$store.state.app.tagList" label="Tags" multiple icon="EditIcon" />
          </div>
          <b-input-group class="input-group-merge search-project"
            style="min-width: 250px; max-width: 250px; width: 250px">
            <b-form-input v-model="debouncedSearch" placeholder="Search by task name / Ticket No." />
            <b-input-group-append is-text>
              <feather-icon v-if="debouncedSearch" icon="XIcon" @click="debouncedSearch = null, search = null" />
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
          <button v-b-tooltip.hover.top class="apply-filter-btn mr-1 ml-75" :disabled="validateFilter" title="Filter"
            @click="applyFilter()">
            <feather-icon icon="SlidersIcon" size="20" />
          </button>
          <button v-b-tooltip.hover.top class="apply-filter-btn mr-1" title="Clear" @click="clearFilter()">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 20 20">
              <g transform="rotate(90 10 10)">
                <g fill="inherit">
                  <path fill-rule="evenodd"
                    d="M3 4.75a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5Zm11.38 0a.5.5 0 0 1 .5-.5h1.62a.5.5 0 0 1 0 1h-1.62a.5.5 0 0 1-.5-.5Z"
                    clip-rule="evenodd" />
                  <path fill-rule="evenodd"
                    d="M12.75 6.5a1.5 1.5 0 1 0 0-3a1.5 1.5 0 0 0 0 3Zm0 1a2.5 2.5 0 1 0 0-5a2.5 2.5 0 0 0 0 5ZM3 14.75a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5Zm11.38 0a.5.5 0 0 1 .5-.5h1.62a.5.5 0 0 1 0 1h-1.62a.5.5 0 0 1-.5-.5Z"
                    clip-rule="evenodd" />
                  <path fill-rule="evenodd"
                    d="M12.75 16.5a1.5 1.5 0 1 0 0-3a1.5 1.5 0 0 0 0 3Zm0 1a2.5 2.5 0 1 0 0-5a2.5 2.5 0 0 0 0 5ZM3 9.75a.5.5 0 0 1 .5-.5h2.13a.5.5 0 0 1 0 1H3.5a.5.5 0 0 1-.5-.5Zm6.5 0a.5.5 0 0 1 .5-.5h6.5a.5.5 0 0 1 0 1H10a.5.5 0 0 1-.5-.5Z"
                    clip-rule="evenodd" />
                  <path fill-rule="evenodd"
                    d="M7.75 11.5a1.5 1.5 0 1 0 0-3a1.5 1.5 0 0 0 0 3Zm0 1a2.5 2.5 0 1 0 0-5a2.5 2.5 0 0 0 0 5Z"
                    clip-rule="evenodd" />
                  <path d="M1.15 1.878a.514.514 0 0 1 .728-.727l16.971 16.971a.514.514 0 0 1-.727.727L1.151 1.878Z" />
                </g>
              </g>
            </svg>
          </button>

        </div>
        <div v-else class="d-flex align-items-center">
          <p class="filter-title ">
            Filters:
          </p>

          <div class="w-lg-100">
            <custom-dropdown v-model="board_project_id" :options="$store.state.app.productiveProjectList"
              :label="projectDtl && projectDtl.name" icon="TableIcon" :hide-close="true"
              :class="projectDtl && projectDtl.code == 'ROJ' && userInfo && userInfo.role == 'EMP' ? 'mr-5' : ''" />
          </div>
        </div>
        <!-- Todo change : 08-08-23  new requirement remove calender-->
        <!-- Calender custom block -->
        <!-- v-if="!this.nonBoard" -->

        <div class="d-flex flex-wrap align-items-center"
          :class="!nonBoard && (!projectDtl || !projectDtl.active_sprint || !projectDtl.active_milestone) ? 'mr-5' : null">

          <Layoutchange v-if="!nonBoard && from !== 'generalBoard'" :type="'board'" />
          <button
            v-if="((!nonBoard && projectDtl && projectDtl.active_sprint && projectDtl.active_milestone) || (nonBoard && userInfo && userInfo.role == 'SA'))"
            id="archive" v-b-tooltip.hover.top class="apply-filter-btn mx-1" title="Archive" @click="redirectToArchive">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 24 24">
              <path fill="currentColor"
                d="M14.5 11c.28 0 .5.22.5.5V13H9v-1.5c0-.28.22-.5.5-.5h5m5.5 2.55V10h-2v3.06c.69.08 1.36.25 2 .49M21 9H3V3h18v6m-2-4H5v2h14V5M8.85 19H6v-9H4v11h5.78c-.24-.39-.46-.81-.64-1.25L8.85 19M17 18c-.56 0-1 .44-1 1s.44 1 1 1s1-.44 1-1s-.44-1-1-1m6 1c-.94 2.34-3.27 4-6 4s-5.06-1.66-6-4c.94-2.34 3.27-4 6-4s5.06 1.66 6 4m-3.5 0a2.5 2.5 0 0 0-5 0a2.5 2.5 0 0 0 5 0Z" />
            </svg>
          </button>

          <BoardBGColor v-if="!nonBoard || nonBoard && userInfo && userInfo.role == 'SA'" :project-dtl="projectDtl"
            :type="nonBoard" />
          <Help
            v-if="userInfo && userInfo.role == 'SA' && nonBoard && $store.state.app.userManualList && $store.state.appConfig.layout.type != 'full'"
            code="Board" />
          <div v-if="from == 'generalBoard'" class="mr-5" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import customDropdown from '@/components/dropdown/customDropdown.vue'
import { eventBus } from '@/main'
import * as moment from 'moment/moment'
import Help from '@/components/HelpDesk/Help.vue'
import Layoutchange from '@/components/layOutChange/LayOutChange.vue'
import debounce from 'lodash/debounce'
import BoardBGColor from './boardBGColor.vue'

export default {
  name: 'BoardFilter',
  components: {
    customDropdown,
    BoardBGColor,
    Help,
    Layoutchange,
  },
  props: {
    projectDtl: {
      type: [Object, null],
      required: true,
      default: null,
    },
    nonBoard: {
      type: Boolean,
      required: true,
      default: false,
    },
    boardProjectId: {
      type: String,
      required: true,
      default: '',
    },
    from: {
      type: String,
      required: true,
      default: 'projectDetail',
    },
  },
  data() {
    return {
      type: [],
      assignee_id: [],
      tags: [],
      search: null,
      dateRangeConfig: {
        mode: 'range',
        inline: true,
      },
      rangeDate: null,
      startDate: null,
      endDate: null,
      isShow: true,
      activeUserList: [],
      board_project_id: (this.projectDtl && this.projectDtl.id) || null,
    }
  },
  computed: {
    debouncedSearch: {
      get() {
        return this.search
      },
      set: debounce(function (value) {
        this.search = value
        this.applyFilter()
      }, 750),
    },
    showDates() {
      return `${moment(this.startDate).format('ddd, MMM DD, YYYY')} - ${moment(
        this.endDate,
      ).format('ddd, MMM DD, YYYY')}`
    },
    validateFilter() {
      return false
    },
    /**
     * Show assignee user data only
     */
    assigneeUsers() {
      if (this.nonBoard) {
        return this.$store.state.app.usersList
      }

      if (this.$store.state.app.projectUsers && !this.$store.state.app.projectUsers.length) {
        return [] // or return an appropriate default value
      }

      // Transform project_user array into users array
      const users = this.$store.state.app.projectUsers.map(element => ({
        value: element.id,
        label: element.full_name,
      }))

      // Sort users list by label
      return users
    },

    addEditRights() {
      if (this.userInfo && this.userInfo.role == 'SA') {
        return true
      }

      if (
        this.$store.state.app.projectUsers && !this.$store.state.app.projectUsers.length
      ) {
        const user = this.$store.state.app.projectUsers && !this.$store.state.app.projectUsers.length.find(
          pu => pu.id === this.userInfo.id,
        )
        if (
          user
          && (user.access_rights === 'project_owner'
            || user.access_rights === 'co_ordinator')
        ) {
          return true
        }
      }
      return false
    },
  },
  watch: {
    boardProjectId(nv) {
      this.board_project_id = nv
    },
    board_project_id(nv) {
      if (nv && this.from == 'projectDetail') {
        this.projectId = nv
      }
      if (this.from !== 'projectDetail') {
        const getGeneralBoard = this.$store.state.app.productiveProjectList.filter(e => e.code === 'ROJ')
        if (getGeneralBoard && getGeneralBoard.length) {
          if (getGeneralBoard[0].id === nv) {
            eventBus.$emit('newProjectId', null)
            return
          }
        }
        eventBus.$emit('newProjectId', nv)
      }
    },
  },
  mounted() {
    this.calendarDataSet()
  },
  methods: {
    calendarDataSet() {
      this.rangeDate = `${moment()
        .startOf('month')
        .format('YYYY-MM-DD')} to ${moment()
          .endOf('month')
          .format('YYYY-MM-DD')}`
      const dateArr = this.rangeDate.split(' to ')
      this.startDate = dateArr[0]
      this.endDate = dateArr[1] ? dateArr[1] : dateArr[0]
    },
    redirectToArchive() {
      let type
      if (this.projectDtl && this.projectDtl.code && this.projectDtl.code === 'ROJ') {
        type = 'general' // general board
      } else {
        type = 'project' // project board
      }
      this.$router.push({
        name: 'archive',
        params: {
          type,
          id: this.projectDtl.id,
          projectname: this.projectDtl.name,
        },
      })
    },

    applyDateFilter() {
      const dateArr = this.rangeDate.split(' to ')
      this.startDate = dateArr[0]
      this.endDate = dateArr[1] ? dateArr[1] : dateArr[0]
      this.calenderFilter()
    },
    closeDateFilter() {
      this.calendarDataSet()
      this.calenderFilter()
    },

    calenderFilter() {
      const filter = {
        assignee: this.assignee_id,
        tags: this.tags,
        search: this.search,
        start_date: this.startDate,
        end_date: this.endDate,
      }
      eventBus.$emit('calenderFilters', filter)
      this.$refs.dateRangeFilter.hide()
    },

    /** * f */
    applyFilter() {
      const filter = {
        assignee: this.assignee_id,
        tags: this.tags,
        search: this.search,
        start_date: this.startDate,
        end_date: this.endDate,
      }

      if (!this.nonBoard) {
        eventBus.$emit('boardApplyFilters', filter)
      } else {
        eventBus.$emit('generalBoardApplyFilters', filter)
      }
    },
    clearFilter() {
      this.assignee_id = []
      this.tags = []
      this.search = null
      const filter = {
        assignee: [],
        start_date: this.startDate,
        end_date: this.endDate,
      }
      if (!this.nonBoard) {
        eventBus.$emit('boardApplyFilters', filter)
      } else {
        eventBus.$emit('generalBoardApplyFilters', filter)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables/_variables.scss";

.card-filter-section {
  background-color: $white;
  margin: 16px 0px 6px 0px;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  padding: 10px 15px;

  .filter-title {
    color: $logo-color;
    font-size: 2.25 * $rem;
    font-weight: $fw500;
  }

  .selectmember {
    cursor: pointer;
    margin-left: 20px;
    background-color: transparent;
    border-color: transparent;
    color: $logo-color;
    display: flex;
    align-items: center;
    position: relative;

    @media (max-width: map-get($mediaSizes , "xl")) {
      margin-left: 18px;
    }

    @media (max-width: map-get($mediaSizes , "lg")) {
      margin-left: 0;
      margin: 7px 0;
    }

    span {
      font-size: 1.75 * $rem;
      font-weight: $fw500;
      text-transform: capitalize;
      line-height: 23px;
    }

    .feather-edit {
      margin-right: 8px;
    }

    .feather-chevron-down {
      margin-left: 8px;
    }

    .badge-pill-filter {
      position: absolute;
      top: -12px;
      right: 13px;
      display: flex;
      height: 20px;
      width: 20px;
      align-items: center;
      justify-content: center;
      padding: 0;
      background-color: rgba(255, 159, 67, 0.7);
    }
  }

  .radio-dropdown {
    position: absolute;
    z-index: 111;
  }

  .apply-filter {
    background-color: $secondary-color;
    border: 1px solid $secondary-color;
    color: $white;
    padding: 8px 11px;
    border-radius: 5px;
    font-size: 2 * $rem;
    font-weight: $fw600;

    @media (max-width: map-get($mediaSizes , "xxl")) {
      font-size: 2 * $rem;
    }

    @media (max-width: map-get($mediaSizes , "xl")) {
      font-size: 1.75 * $rem;
    }

    @media (max-width: map-get($mediaSizes , "lg")) {
      font-size: 1.5 * $rem;
    }
  }

  .apply-filter-clear {
    border: 1px solid $secondary-color;
    color: $secondary-color;
    padding: 8px 20px;
    border-radius: 5px;
    font-size: 2 * $rem;
    font-weight: $fw600;
    background-color: transparent;

    @media (max-width: map-get($mediaSizes , "xxl")) {
      font-size: 2 * $rem;
    }

    @media (max-width: map-get($mediaSizes , "xl")) {
      font-size: 1.75 * $rem;
    }

    @media (max-width: map-get($mediaSizes , "lg")) {
      font-size: 1.5 * $rem;
    }
  }

  .search-project {
    border-radius: 5px;
    color: $dark-gray;

    &:focus-visible {
      outline: $primary-color;
    }

    .form-control {
      border-color: $input-outline;
      padding: 6px 0 6px 20px;
      color: $logo-color !important;
      height: 33px;

      &::placeholder {
        color: $dark-gray;
        font-size: 1.5 * $rem;
        font-weight: $fw500;
      }
    }
  }
}

.calendar {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: map-get($mediaSizes , "lg")) {
    flex-wrap: wrap;
    margin-top: 20px;
    width: 100%;
    margin-bottom: 5px;
  }

  .date-select {
    display: flex;
    align-items: center;
    margin-right: 55px;

    @media all and (max-width: 1320px) {
      margin-right: 0px;
    }

    .calender-prev-next {
      margin-right: 10px;
      background-color: rgba(33, 120, 251, 0.07);
      color: #005eff;
      cursor: pointer;
      font-weight: $fw600;
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;

      @media (max-width: map-get($mediaSizes , "md")) {
        margin-bottom: 10px;
      }
    }

    .btn.dropdown-toggle {
      color: #000 !important;
    }

    .calendar-next {
      margin-left: 10px;
    }

    .date-dropdown {
      position: relative;

      .date-picker {
        background-color: rgba(33, 120, 251, 0.07);
        padding: 12px 22px 12px 22px;
        cursor: pointer;
        display: flex;
        align-items: center;
        border-radius: 5px;
        font-size: 1.75 * $rem;
        line-height: 24px;
        font-weight: $fw600;
        position: relative;
        width: 320px;

        @media (max-width: map-get($mediaSizes , "md")) {
          margin-bottom: 5px;
          width: 320px !important;
        }

        button {
          text-align: center !important;

          &.dropdown-toggle {
            color: $black-color !important;
            size: 1.75 * $rem;
            font-weight: $fw600;
            line-height: 24px;
            border: none !important;
          }
        }

        .dropdown-menu {
          .calendar-icon {
            display: block;
          }
        }
      }

      .calendar-icon {
        color: #005eff;
        position: absolute;
        top: 16px;
        right: 0px;
        margin-right: 14px;
      }

      .report-date-picker {
        width: 350px;

        @media (max-width: map-get($mediaSizes , "md")) {
          width: 250px;
        }
      }
    }
  }

  .timesheet-select-project {
    padding: 2px;

    .vs__dropdown-toggle {
      border: 1px solid $input-outline;
    }

    .vs__open-indicator {
      fill: $logo-color;
    }

    .vs__selected {
      background-color: $secondary-color;
      color: $white;
      font-weight: $fw500;
      border-color: $secondary-color;
      font-size: 12px;
    }

    .vs__deselect {
      fill: $white;
    }

    .vs__search {
      &::placeholder {
        color: $font-color;
      }
    }

    .vs__dropdown-menu {
      border-radius: 10px;
      scroll-behavior: smooth;
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        background-color: transparent;

        & ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 2px rgba(184, 184, 184, 0.3);
          background-color: transparent;
          border-radius: 10px;
        }
      }

      &::-webkit-scrollbar-thumb {
        // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 5px rgba(184, 184, 184, 0.3);
        border-radius: 10px;
        background-color: $scroller-color;
      }

      .vs__dropdown-option {
        text-transform: capitalize;
        font-weight: $fw500;
        line-height: 28px;
        width: 237px;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;

        &:hover {
          color: $primary-color;
          background-color: $blue;
        }
      }
    }
  }

  .timesheet-select-user {
    padding: 2px;

    .vs__open-indicator {
      fill: $logo-color;
    }

    .vs__clear {
      fill: $logo-color;
    }

    .vs__dropdown-toggle {
      border: 1px solid $input-outline;
    }

    .vs__dropdown-menu {
      border-radius: 10px;
      scroll-behavior: smooth;
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        background-color: transparent;

        & ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 2px rgba(184, 184, 184, 0.3);
          background-color: transparent;
          border-radius: 10px;
        }
      }

      &::-webkit-scrollbar-thumb {
        // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 5px rgba(184, 184, 184, 0.3);
        border-radius: 10px;
        background-color: $scroller-color;
      }

      .vs__dropdown-option {
        text-transform: capitalize;
        font-weight: $fw500;
        line-height: 28px;

        &:hover {
          color: $primary-color;
          background-color: $blue;
        }
      }
    }

    .vs__selected {
      color: $logo-color;
      font-weight: $fw400;
    }

    .vs__search {
      &::placeholder {
        color: $font-color;
      }
    }
  }
}

.flatpickr-input {
  display: none !important;
}

.flatpickr-innerContainer {
  margin-top: 20px;

  span.flatpickr-weekday {
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 18px;
    color: #2178fb;
  }
}

.flatpickr-calendar .flatpickr-day.flatpickr-disabled,
.flatpickr-calendar .flatpickr-day.prevMonthDay,
.flatpickr-calendar .flatpickr-day.nextMonthDay {
  color: #bdbdbd;
}

.flatpickr-calendar .flatpickr-day {
  color: #333333;
}
</style>
